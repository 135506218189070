import React, { useEffect, useRef, useState } from "react";
import {
  Page,
  Navbar,
  NavbarBackLink,
  Tabbar,
  TabbarLink,
  Block,
  Icon,
  List,
  ListItem,
  Toggle,
  ListInput,
  Fab,
  Button,
  Panel,
  Link,
  Preloader,
  Segmented,
  SegmentedButton,
} from "konsta/react";
import {
  MdEmail,
  MdToday,
  MdFileUpload,
  MdWhatsapp,
  MdSend,
  MdHelp,
  MdReportProblem,
  MdHelpCenter,
} from "react-icons/md";

const App = () => {
  const [activeTab, setActiveTab] = useState("tab-1");
  const [rightPanelOpened, setRightPanelOpened] = useState(false);
  const [activeSegmented, setActiveSegmented] = useState(1);

  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(" sliderRef.current", sliderRef.current);
  }, [activeTab]);

  const chatGpt = () => {
    if (!msg || loading) return;
    setLoading(true);
    var formdata = new FormData();
    formdata.append(
      "prompt",
      'Перепишите как бы это сделал НЛП специалист по обшению следующий текст, исправив синтаксические и грамматические ошибки, но не добавляя новых подробностей: "' +
        msg +
        '"'
    );

    var requestOptions: any = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://takeandmake.apptor.tech/gpt", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setMsg(result.result);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setLoading(false);
      });
  };

  const [isTabbarIcons, setIsTabbarIcons] = useState(false);
  const sliderRef = useRef();
  return (
    <Page>
      <Tabbar labels icons={isTabbarIcons} className="left-0 bottom-0 fixed">
        <TabbarLink
          active={activeTab === "tab-1"}
          onClick={() => setActiveTab("tab-1")}
          icon={<MdWhatsapp size={30} />}
          label={"WhatsApp"}
        />
        <TabbarLink
          active={activeTab === "tab-2"}
          onClick={() => setActiveTab("tab-2")}
          icon={<MdSend size={30} />}
          label={"Telegram"}
        />
        <TabbarLink
          onClick={() => setRightPanelOpened(true)}
          //   active={activeTab === "tab-3"}
          //  onClick={() => setActiveTab("tab-3")}
          icon={<MdHelp size={30} />}
          label="Помощь"
        />
      </Tabbar>

      <div className="w-full h-full">
        <webview
          style={{
            height: window.innerHeight - 60,
            left: activeTab == "tab-2" ? 0 : -3000,
          }}
          src="https://web.telegram.org/k/"
          className="w-full top-0  absolute "
        ></webview>

        <webview
          style={{
            height: window.innerHeight - 60,
            left: activeTab == "tab-1" ? 0 : -3000,
          }}
          src="https://web.whatsapp.com"
          className="w-full top-0 absolute "
        ></webview>

        <div
          style={{
            left: activeTab == "tab-3" ? 0 : -3000,
          }}
          className="w-full h-full absolute "
        ></div>
      </div>

      <Panel
        side="right"
        opened={rightPanelOpened}
        onBackdropClick={() => setRightPanelOpened(false)}
      >
        <Page>
          <Navbar
            title="Помощь"
            right={
              <Link navbar onClick={() => setRightPanelOpened(false)}>
                Закрыть
              </Link>
            }
          />

          <Block strongIos outlineIos className="space-y-4">
            <Segmented strong>
              <SegmentedButton
                strong
                active={activeSegmented === 1}
                onClick={() => setActiveSegmented(1)}
              >
                Проверка
              </SegmentedButton>
              <SegmentedButton
                strong
                active={activeSegmented === 2}
                onClick={() => setActiveSegmented(2)}
              >
                Скрипты
              </SegmentedButton>
            </Segmented>
          </Block>
          {activeSegmented == 1 && (
            <>
              <List className="strongIos insetIos">
                <ListInput
                  label="Сообщение"
                  value={msg}
                  onChange={(e: any) => {
                    setMsg(e.target.value);
                  }}
                  type="textarea"
                  placeholder="Сообщение"
                  className="!h-22"
                  inputStyle={{
                    height: 200,
                  }}
                  inputClassName="!h-22 resize-none"
                />
              </List>

              <Block>
                <div className="!mx-4">
                  {loading ? (
                    <Preloader />
                  ) : (
                    <Button onClick={chatGpt}>
                      Проверить ошибки и написание
                    </Button>
                  )}
                </div>
              </Block>
            </>
          )}
        </Page>
      </Panel>
    </Page>
  );
};

export default App;
